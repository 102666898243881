import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import instance from '../../AxiosInstance';
import { DealType } from '../../Enums/Deal';

type FetchModelParams = {
  id: number;
};

export type CommissionModel = {
  id: number;
  deal_type: DealType;
  label: string;
  edu_c2c_label: string;
  description: string;
};

export const fetchModel = createAsyncThunk<CommissionModel, FetchModelParams>(
  'commissionModel/fetchModel',
  async (params, { rejectWithValue }) => {
    try {
      const { id } = params;

      if (!id) {
        throw new Error(
          'Cannot proceed without an id for the Commission Model.',
        );
      }

      const response = await instance.get<CommissionModel>(
        `/deals/commission-model/${id}`,
      );

      return response.data;
    } catch (error: any) {
      Sentry.captureException(error);
      const val = error.message || error;
      return rejectWithValue(val);
    }
  },
);
