import { format } from 'date-fns';

/**
 * Returns a date in the format of 'MMM do', e.g. 'Aug 27'.
 * @param formatDate - in the format of 'YYYY-MM-DD'
 */
const formatUTCDateOnly = (formatDate?: string) => {
  if (!formatDate) {
    return 'Unknown';
  }

  const parts = formatDate.split('-');
  if (parts.length !== 3) {
    return 'Unknown';
  }

  for (let i = 0; i < parts.length; i += 1) {
    if (Number.isNaN(Number(parts[i]))) {
      return 'Unknown';
    }
  }

  const year = Number(parts[0]);
  const month = Number(parts[1]);
  const day = Number(parts[2]);

  const _date = new Date(year, month - 1, day);
  return format(_date, 'MMM do');
};

export default formatUTCDateOnly;
