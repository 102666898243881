const getCommissionModelName = (commissionModelName) => {
  switch (commissionModelName) {
    case 'Rewards Buyer':
    case 'Traditional Model':
    case 'Buyer No Clever Cash Back':
      return 'Set your own commission';
    case 'Rewards Seller':
    case '1.5% Model':
      return '1.5% Model';
    case 'Buyer Model (new $250)':
      return 'Clever Cash Back';
    case 'Clever Cash Back ($250)':
      return 'Lender Partner ($250)';
    case 'Clever Cash Back (0.5%)':
      return 'Lender Partner (0.5%)';
    default:
      return '';
  }
};

export default getCommissionModelName;
