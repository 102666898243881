/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { sendToast } from '../../utils';
import { Step } from '../../components/NewUpdateModal/utils/enums';
import { DealType } from '../../Enums/Deal';
import { postUpdate } from './actions';

const initialState = {
  updateModalOpen: false,
  dealType: DealType.Buyer,
  currentStep: Step.DisplayOptions,
  error: null as any,
  data: null as any,
  successfullyUpdated: false,
  loading: false,
  refreshConnection: false,
};

const updateSlice = createSlice({
  name: 'update',
  initialState,
  reducers: {
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    },
    setDealType: (state, { payload }) => {
      state.dealType = payload;
    },
    setUpdateModalOpen: (state, { payload }) => {
      state.updateModalOpen = payload;
    },
    resetCurrentStep: (state) => {
      state.currentStep = Step.DisplayOptions;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearRefreshConnection: (state) => {
      state.refreshConnection = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postUpdate.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (!payload.error) {
        state.successfullyUpdated = true;
        state.data = payload;
        if (meta.arg.nextPage) {
          state.currentStep = meta.arg.nextPage;
          state.refreshConnection = true;
        } else {
          state.currentStep = Step.NewReferral;
          state.updateModalOpen = false;
          state.refreshConnection = true;
        }
      } else {
        state.error = payload.error;
        sendToast(payload.error, { type: 'error' });
      }
    });
    builder.addCase(postUpdate.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
  },
});

export const {
  setCurrentStep,
  setDealType,
  setUpdateModalOpen,
  resetCurrentStep,
  clearError,
  clearRefreshConnection,
} = updateSlice.actions;

export default updateSlice.reducer;
