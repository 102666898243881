import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import instance from '../../AxiosInstance';

interface UpdateDealProps {
  id: number;
  data: any;
}

export interface ErrorResponse {
  error: boolean;
  message: string | object;
}

// Pagination
export const updateDeal = createAsyncThunk<
  Deal | ErrorResponse,
  UpdateDealProps
>('connections/requestNextConnections', async (props) => {
  try {
    const response = await instance.patch(`/deals/${props.id}/`, {
      ...props.data,
    });

    return response.data;
  } catch (err) {
    const typedErr: any = err;
    let message = 'There was an error updating the deal. Please try again';
    Sentry.captureException(err);
    if (typedErr.response.data) {
      message = typedErr.response.data;
    }
    return {
      error: true,
      message,
    };
  }
});
